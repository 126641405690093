import { prop } from 'datx';
import { Resource } from 'datx-jsonapi-react';

import { MainStageType } from '@/enums/MainStageType';

export class EventConfiguration extends Resource {
  public static type = 'event_configurations';

  @prop
  public receptionTicketPrompt: boolean;

  @prop
  public expoPage: boolean;

  @prop
  public directMessaging: boolean;

  @prop
  public expoPageLabel: string;

  @prop
  public expoPagePublic: boolean;

  @prop
  public helpdeskPage: boolean;

  @prop
  public helpdeskPageLabel: string;

  @prop
  public helpDeskPagePublic: boolean;

  @prop
  public loungePage: boolean;

  @prop
  public loungePageLabel: string;

  @prop
  public loungePagePublic: string;

  @prop
  public mainStagePage: boolean;

  @prop
  public mainStagePageLabel: string;

  @prop
  public mainStagePagePublic: string;

  @prop
  public mainStageType: MainStageType;

  @prop
  public postersPage: boolean;

  @prop
  public postersPageLabel: string;

  @prop
  public postersPagePublic: boolean;

  @prop
  public receptionPage: boolean;

  @prop
  public receptionPageLabel: string;

  @prop
  public receptionPagePublic: boolean;

  @prop
  public schedulePage: boolean;

  @prop
  public embeddingScheduleType: EmbeddingScheduleType;

  @prop
  public schedulePageLabel: string;

  @prop
  public schedulePagePublic: boolean;

  @prop
  public sessionsPage: boolean;

  @prop
  public sessionsPageLabel: string;

  @prop
  public sessionsPagePublic: boolean;

  @prop
  public lecturesAbstractPage: boolean;

  @prop
  public lecturesAbstractPagePublic: boolean;

  @prop
  public lecturesAbstractPageLabel: string;

  @prop
  public lecturesDropdownLabel: string;

  @prop
  public tracksLabelName: string;

  @prop
  public sessionsLabelName: string;
}

export enum EmbeddingScheduleType {
  REGULAR = 'regular',
  SIMPLIFIED = 'simplified',
}
