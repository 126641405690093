import '../networking/network';
import { IModelConstructor, IRawModel, IType } from 'datx';
import { Client } from 'datx-jsonapi-react';
import { GetServerSidePropsContext } from 'next';

import { AgendaSessions } from '@/resources/AgendaSessions';
import { AlgoliaFilterItem } from '@/resources/AlgoliaFilterItem';
import { Article } from '@/resources/Article';
import { Banner } from '@/resources/Banner';
import { BaseModel } from '@/resources/BaseModel';
import { CaseStudy } from '@/resources/CaseStudy';
import { CaseStudyCategory } from '@/resources/CaseStudyCategory';
import { Chapter } from '@/resources/Chapter';
import { ChatMessages } from '@/resources/ChatMessages';
import { Comment } from '@/resources/Comment';
import { ConferenceHubCounters } from '@/resources/ConferenceHubCounters';
import { Country } from '@/resources/Country';
import { EventAnnouncement } from '@/resources/EventAnnouncement';
import { EventChatChannel } from '@/resources/EventChatChannel';
import { EventChatChannelParticipant } from '@/resources/EventChatChannelParticipant';
import { EventConfiguration } from '@/resources/EventConfiguration';
import { EventDay } from '@/resources/EventDay';
import { EventHubCounters } from '@/resources/EventHubCounters';
import { EventSponsor } from '@/resources/EventSponsor';
import { EventSponsorPackage } from '@/resources/EventSponsorPackage';
import { EventSponsorStatus } from '@/resources/EventSponsorStatus';
import { EventTicket } from '@/resources/EventTicket';
import { GoogleScholarTags } from '@/resources/GoogleScholarTags';
import { Hub } from '@/resources/Hub';
import { HubsSubjectsSubtree } from '@/resources/HubsSubjectsSubtree';
import { Keywords } from '@/resources/Keywords';
import { LectureAccessPermissions } from '@/resources/LectureAccessPermissions';
import { LecturePackages } from '@/resources/LecturePackages';
import { LecturePlaylist } from '@/resources/LecturePlaylist';
import { LectureProfile } from '@/resources/LectureProfile';
import { LectureStatistic } from '@/resources/LectureStatistic';
import { LectureTags } from '@/resources/LectureTags';
import { LectureYears } from '@/resources/LectureYears';
import { LiblynxAuthorizationGrants } from '@/resources/LiblynxAuthorizationGrants';
import { LibrarySubjects } from '@/resources/LibrarySubjects';
import { Livestream } from '@/resources/Livestream';
import { Lounge } from '@/resources/Lounge';
import { MarketingDocument } from '@/resources/MarketingDocument';
import { MenuGroup } from '@/resources/MenuGroup';
import { MenuGroupMember } from '@/resources/MenuGroupMember';
import { MenuTab } from '@/resources/MenuTab';
import { NewsArticle } from '@/resources/NewsArticle';
import { NewsArticleTopic } from '@/resources/NewsArticleTopic';
import { Notification } from '@/resources/Notification';
import { OmnisearchFilterItem } from '@/resources/OmnisearchFilterItem';
import { OmnisearchLecturesFilter } from '@/resources/OmnisearchLecturesFilter';
import { OmnisearchOccurrence } from '@/resources/OmnisearchOccurrence';
import { OmnisearchThinEvent } from '@/resources/OmnisearchThinEvent';
import { OmnisearchThinLecture } from '@/resources/OmnisearchThinLecture';
import { OriginalsEpisode } from '@/resources/OriginalsEpisode';
import { OriginalsEpisodeDetails } from '@/resources/OriginalsEpisodeDetails';
import { OriginalsEpisodeTranscripts } from '@/resources/OriginalsEpisodeTranscripts';
import { OriginalsMaterial } from '@/resources/OriginalsMaterial';
import { OriginalsSeries } from '@/resources/OriginalsSeries';
import { ProfileInstitutions } from '@/resources/ProfileInstitutions';
import { ProfilePlaylist } from '@/resources/ProfilePlaylist';
import { ProfileStatistics } from '@/resources/ProfileStatistics';
import { Reaction } from '@/resources/Reaction';
import { ResourceRole } from '@/resources/ResourceRole';
import { ScheduleEntry } from '@/resources/ScheduleEntry';
import { Session } from '@/resources/Session';
import { Tag } from '@/resources/Tag';
import { Testimonial } from '@/resources/Testimonial';
import { ThinConference } from '@/resources/ThinConference';
import { ThinEvent } from '@/resources/ThinEvent';
import { ThinEventSession } from '@/resources/ThinEventSession';
import { ThinLecture } from '@/resources/ThinLecture';
import { ThinProfile } from '@/resources/ThinProfile';
import { ThinSubject } from '@/resources/ThinSubject';
import { Token } from '@/resources/Token';
import { Track } from '@/resources/Track';
import { Transcript } from '@/resources/Transcript';
import { TranscriptLanguage } from '@/resources/TranscriptLanguage';
import { User } from '@/resources/User';

export class AppCollection extends Client {
  public static types = [
    Article,
    Banner,
    CaseStudy,
    CaseStudyCategory,
    Chapter,
    Country,
    EventAnnouncement,
    EventChatChannel,
    EventChatChannelParticipant,
    EventConfiguration,
    EventDay,
    ThinEventSession,
    EventSponsor,
    EventSponsorStatus,
    EventSponsorPackage,
    EventTicket,
    Keywords,
    LiblynxAuthorizationGrants,
    LectureTags,
    LectureProfile,
    LectureYears,
    LibrarySubjects,
    LecturePackages,
    Livestream,
    Lounge,
    MenuGroup,
    MenuGroupMember,
    MenuTab,
    NewsArticle,
    NewsArticleTopic,
    ResourceRole,
    ScheduleEntry,
    Session,
    Token,
    Track,
    Transcript,
    TranscriptLanguage,
    User,
    Tag,
    ThinProfile,
    ThinLecture,
    LectureAccessPermissions,
    Notification,
    ThinEvent,
    ThinConference,
    Comment,
    Reaction,
    ThinSubject,
    OriginalsEpisode,
    OriginalsMaterial,
    OriginalsSeries,
    OriginalsEpisodeDetails,
    OriginalsEpisodeTranscripts,
    OmnisearchThinLecture,
    OmnisearchLecturesFilter,
    OmnisearchFilterItem,
    OmnisearchThinEvent,
    GoogleScholarTags,
    Hub,
    HubsSubjectsSubtree,
    AlgoliaFilterItem,
    ConferenceHubCounters,
    EventHubCounters,
    ProfileInstitutions,
    OmnisearchOccurrence,
    ProfilePlaylist,
    LecturePlaylist,
    LectureStatistic,
    ProfileStatistics,
    Testimonial,
    MarketingDocument,
    AgendaSessions,
    ChatMessages,
  ];

  cookie: string;

  constructor(data?: Array<IRawModel>, context?: GetServerSidePropsContext) {
    super(data);
    this.cookie = context?.req?.headers?.cookie;
  }

  public findSingleton<T>(type: IType) {
    const models = this.findAll<T>(type);

    if (models.length > 0) {
      return models[0];
    }

    return null;
  }

  public findByIds<T extends BaseModel>(type: IType | IModelConstructor<T>, ids: Array<string> = []) {
    const items = this.findAll<T>(type).filter((record: BaseModel) => ids.includes(record.id));

    const sortItems = (a, b) => {
      return ids.indexOf(a) - ids.indexOf(b);
    };

    return [...items].sort((a, b) => sortItems(a.id, b.id));
  }

  /**
   * This method is used to connect swr and datx
   * @param url string
   */
  public fetcher = (url: string) => {
    return this.request(url, 'GET');
  };
}
