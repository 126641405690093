import { prop } from 'datx';
import { Resource } from 'datx-jsonapi-react';
import { computed } from 'mobx';

import { EventChatChannelParticipant } from '@/resources/EventChatChannelParticipant';
import { Notification } from '@/resources/Notification';

export class EventChatChannel extends Resource {
  public static type = 'event_chat_channels';

  @prop
  public twilioChatChannel: string;

  @prop
  public lastActivityAt: string;

  @prop
  public unreadMessages: number;

  @prop.toMany('event_chat_channel_participants', 'eventChatChannel')
  public eventChatChannelParticipants: Array<EventChatChannelParticipant>;

  @prop.toMany('notifications', 'eventChatChannel')
  public notifications: Array<Notification>;

  @computed
  public get eventChatChannelParticipant() {
    return this.eventChatChannelParticipants[0];
  }

  @computed
  public get notification() {
    if (this.notifications?.length) {
      return this.notifications[0];
    }
    return null;
  }
}
