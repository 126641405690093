import { prop } from 'datx';
import { Resource } from 'datx-jsonapi-react';

export class ChatMessages extends Resource {
  public static type = 'chat_messages';

  @prop
  public channelId: number;

  @prop
  public senderId: number;

  @prop
  public recipientId: number;

  @prop
  public content: string;
}
