import { prop } from 'datx';
import { computed } from 'mobx';

import { Resource } from '@/lib/datx-jsonapi-react';
import { EventChatChannelParticipant } from '@/resources/EventChatChannelParticipant';
import { ThinProfile } from '@/resources/ThinProfile';
import { PackageType } from '@/types/PackageType';

export class User extends Resource {
  public static type = 'users';

  @prop
  public email: string;

  @prop
  public package: PackageType;

  @prop
  public firstName: string;

  @prop
  public lastName: string;

  @prop
  public termsAcceptedAt: string;

  @prop
  public role: string;

  @prop
  public eventManagersCount: number;

  @prop.toMany('thin_profiles', 'user')
  public profiles: Array<ThinProfile>;

  @computed
  public get firstProfile() {
    return this.profiles[0];
  }

  @computed
  public get isEventManager() {
    return this.eventManagersCount > 0;
  }

  public get fullName(): string {
    if (!this.firstName || !this.lastName) {
      return this.email?.replace(/@.*/, '');
    }

    return `${this.firstName} ${this.lastName}`;
  }
}
